
import axios from 'axios'

//const configApi = 'https://api.ticketshop.com.co/api/'
const configApi = 'https://api-ad.ticketshop.com.co/api'
//const configApi = 'http://localhost:8000/api/'

const api = axios.create({
    baseURL: configApi
})



export default api
